import React from 'react'

import Video from '../../images/Brian.mp4'

const VideoSection = ({ videoSrcURL, videoTitle, ...props }) => (
  <section
    className=""
    // style={{
    //   backgroundImage: `url(${physicianBG})`,
    //   backgroundColor: '#012d6f',
    //   backgroundPosition: 'center',
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    // }}
  >
    <div className="container px-5 py-5 mx-auto">
      <div className="row align-items-center box-shadow video-bg">
        <div className="col-12 col-lg-6 mx-auto h-100 px-0">
          <div className="">
            <div className="">
              <div className="vid-section">
                <iframe
                  title="video"
                  src={`${Video}?controls=1&autoplay=1&mute=0`}
                  frameBorder="0"
                  allowFullScreen
                  autoPlay
                  controls
                  preload="auto"
                  aria-label="Video playback"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-lg-6 px-0">
          <div className="left-info primary-bg-color x">
            <h2 className="text-white f-poppins mb-3">Hi I'm Brian Boals</h2>
            <ul className="arrow-list">
              <li>Smart strategies from marketing, negotiating, and closing</li>
              <li>Creative ways to stage your home</li>
              <li>Practical advice to help avoid some costly mistake</li>
              <li>
                Tips to find trusted lenders, the best medical care, handyman,
                cleaning services, and more
              </li>
            </ul>
            <p className="text-white f-poppins mb-3">
              I am an active, aggressive, full service real estate professional
              serving Colorado Springs and all of the surrounding areas. I am
              committed to taking the greatest care of you and your referrals
              with unmatched tenacity and professionalism.
            </p>
            <p className="text-white f-poppins mb-3">
              My goals are to make the buying and selling experience smooth and
              efficient. I earn my clients' trust and loyalty, and they become
              lifelong clients and friends. I have over 20 years of experience
              to put to work for you!
            </p>
          </div>
        </div> */}
      </div>
    </div>
  </section>
)

export default VideoSection
