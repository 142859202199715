import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import Hero from '../components/homesection/hero.js'
import Prediction from '../components/homesection/boldPrediction.js'
import Benefits from '../components/homesection/benefits.js'
import VideoSection from '../components/homesection/video.js'
import Watch from '../components/homesection/watchTestimonial.js'
import First from '../components/homesection/firstBuyers.js'
import Relocation from '../components/homesection/physician-relocation.js'
import MilitaryRelocation from '../components/homesection/military-relocation.js'
import FeaturedListing from '../components/homesection/featured-listings.js'
import FeaturedSearch from '../components/homesection/featuredsearch.js'
import FeaturedProperties from '../components/homesection/featuredproperties.js'
import Neighborhoods from '../components/homesection/neighborhoods.js'
import OneStop from '../components/homesection/onestop.js'
import Evaluation from '../components/homesection/homevaluation.js'
import Physician from '../components/homesection/physician'
import Statistics from '../components/homesection/statistics.js'
import LivesInColorado from '../components/homesection/livesincolorado.js'
import Military from '../components/homesection/military.js'
import Testimonials from '../components/homesection/testimonials.js'
import Articles from '../components/homesection/articles.js'
import Remax from '../components/homesection/remax.js'
import Contact from '../components/homesection/contact.js'
import PhysicianTab from '../components/physicianTab'
// import OnloadModal from '../components/homesection/OnloadModal'
import './index.css'
import { graphql } from 'gatsby'
import qs from 'qs'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: null,
      initialQueryParams: null,
      hasStateChanged: false,
      range: null,
    }
  }
  // componentDidMount() {
  //   let queryParams
  //   try {
  //     queryParams = qs.parse(window.location.search.replace('?', ''))
  //   } catch (err) {
  //     // empty query params
  //   }
  //   // this.setState({ initialQueryParams: queryParams })
  // }

  onSearchStateChange = searchState => {
    const updateAfter = 700
    const queryParams = qs.stringify([searchState.menu, searchState.range])

    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      this.setState({
        menu: searchState && searchState.menu,
        hasStateChanged: true,
        range: searchState && searchState.range,
        initialQueryParams: queryParams,
      })
      // navigate(`/?${queryParams}`, {})
    }, updateAfter)
  }

  render() {
    const { data } = this.props
    const location = this.state.initialQueryParams

    return (
      <Layout
        head={{ title: 'Home' }}
        location={location}
        history={this.props.history}
        onSearchStateChange={this.onSearchStateChange}
      >
        <main>
          <Hero location={location} />
          <VideoSection />
          <Prediction />
          <Benefits />
          <Watch />
          <First />
          <Relocation />
          <MilitaryRelocation />
          <FeaturedListing />
          {/* <PhysicianTab /> */}
          <FeaturedProperties
            listings={data.allSanityListing.edges.map(listing => listing.node)}
          />
          <Evaluation />
          {/* <FeaturedSearch /> */}
          {/* <Neighborhoods />
          <OneStop /> */}
          {/* <Statistics /> */}
          {/* <LivesInColorado /> */}
          {/* <Military /> */}
          {/* <Physician /> */}
          {/* <Testimonials
            testimonials={data.allSanityTestimonial.edges.map(
              testimonial => testimonial.node
            )}
          />
          <Articles posts={data.allSanityPost.edges.map(post => post.node)} /> */}
          {/* <Remax />
          <Contact /> */}
        </main>
      </Layout>
    )
  }
}

export default IndexPage

export const Query = graphql`
  query {
    allSanityListing(
      filter: { isFeatured: { eq: true } }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          PropertyType
          UnparsedAddress
          ListPrice
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          StandardStatus
          _createdAt
          Media {
            asset {
              url
            }
          }
        }
      }
    }
    allSanityTestimonial(limit: 5, sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          name
          rating
          description
          publishedAt
        }
      }
    }
    allSanityPost(limit: 3) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          shortdesc
          body
          _createdAt
          mainImage {
            asset {
              url
            }
          }
          author {
            name
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`
